<template>
  <div class="type-five">
    <v-img
      height="480px"
      lazy-src="@/assets/images/lazy-image.png"
      :src="getAsset(imageSrc)"
    />
    <div class="inner">
      <div class="content">
        <h2 class="text-title" v-html="title" />
        <p class="text-desc font-18" v-html="description" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TypeFive",
  props: {
    imageSrc: {
      type: String,
      default: "images/image-2.webp"
    },
    title: {
      type: String,
      default: "title"
    },
    description: {
      type: String,
      default: "description"
    }
  },
  methods: {
    getAsset(path) {
      return require("@/assets/" + path);
    }
  }
};
</script>

<style lang="sass" scoped>
.type-five
  position: relative
  .inner
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    height: 100%
    width: 100%
    transition: .5s ease
    background-color: #00000080
    .content
      color: white
      font-size: 20px
      position: absolute
      top: 50%
      left: 50%
      -webkit-transform: translate(-50%, -50%)
      -ms-transform: translate(-50%, -50%)
      transform: translate(-50%, -50%)
      text-align: center
      width: 100%
      padding: 0 12px
      .text-title
        margin-bottom: 32px
</style>
