<template>
  <v-row class="type-six">
    <v-col class="right" cols="12" sm="6">
      <v-img
        height="100%"
        lazy-src="@/assets/images/lazy-image.png"
        :src="getAsset(imageSrc)"
      />
    </v-col>
    <v-col class="left" cols="12" sm="6">
      <div class="content">
        <div class="mx-auto">
          <h3 class="text-title" style="color: #0066E3" v-html="title" />
          <p class="text-desc" style="color: #000000" v-html="description" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TypeSix",
  props: {
    imageSrc: {
      type: String,
      default: "images/image-3.webp"
    },
    title: {
      type: String,
      default: "title"
    },
    description: {
      type: String,
      default: "description"
    }
  },
  methods: {
    getAsset(path) {
      return require("@/assets/" + path);
    }
  }
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'
.type-six
  margin-top: 12px
  margin-bottom: 12px
  .left, .right
    padding-top: 0
    padding-bottom: 0
    height: auto
  .left
    height: 100%
    .content
      background-color: #FAF7F5
      display: flex
      text-align: center
      align-items: center
      height: 100%
      padding: 32px 10px
      div
        max-width: 460px

@media #{map-get($display-breakpoints, 'sm-and-up')}
  .type-six
    margin-top: 24px
    margin-bottom: 24px
    .left, .right
      padding-top: 0
      padding-bottom: 0
      height: 440px
    .left
      .content
        background-color: #FAF7F5
        display: flex
        text-align: center
        align-items: center
        height: 100%
        div
          max-width: 460px
        .text-title
          margin-bottom: 34px
</style>
