<template>
  <div class="card-type-one">
    <v-img
      class="image"
      lazy-src="@/assets/images/lazy-image.png"
      :src="getAsset(imageSrc)"
    />
    <h3 class="text-title" v-html="title" />
    <p class="text-desc font-18" v-html="description" />
  </div>
</template>

<script>
export default {
  name: "CardTypeOne",
  props: {
    imageSrc: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: "title"
    },
    description: {
      type: String,
      default: "description"
    }
  },
  methods: {
    getAsset(path) {
      return require("@/assets/" + path);
    }
  }
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'
.card-type-one
  text-align: center
  .image
    width: 200px
    margin: 0 auto
  .text-title
    font-weight: bold
    font-size: 22px !important
    color: #0066E3
    margin-bottom: 24px
    margin-top: 24px
@media #{map-get($display-breakpoints, 'md-and-up')}
  .card-type-one
    text-align: center
    .image
      min-width: 200px
    .text-title
      font-weight: bold
      font-size: 22px !important
      color: #0066E3
      height: 80px
</style>
