<template>
  <div class="type-four">
    <v-container class="content">
      <slot></slot>
      <go-to-contact />
    </v-container>
  </div>
</template>

<script>
import GoToContact from "./GoToContact";
export default {
  name: "TypeFour",
  components: { GoToContact }
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'
.type-four
  background-color: #FAF7F5
  .content
    padding: 32px 12px
    max-width: 980px
    margin: 0 auto
@media #{map-get($display-breakpoints, 'sm-and-up')}
  .type-four
    .content
      padding: 80px 12px
      max-width: 980px
      margin: 0 auto
</style>
